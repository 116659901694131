.teamMate:after {
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    clear: both;
    visibility: hidden;
}

.teamMate h2 {
    font-size: 1.3em;
    font-weight: normal;
    /* padding-top: 17px; */
    margin: 0;
}

.contact-item {
    padding: 0px;
}

.teamMate p {
    margin: 0;
    font-size: .8em;
}

.teamMate img {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 50px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 50%;
}

.mate-info {
    display: inline-block;
    vertical-align: top;
}

.panelbar-wrapper-menu {
    max-width: 300px;
    min-width: 300px;
    margin: 0 auto;
}

.contact-item {
    background: white;
    padding: 0px 0px;
    width: 100%;
    display: flex;
    align-items: center;
}


/* width */

::-webkit-scrollbar {
    width: 7px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: rgb(194, 191, 191);
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}